.wrapper {
  animation-name: fade-in;
  animation-duration: 300ms;
  animation-timing-function: var(--base-timing-fn);
}

.row {
  display: flex;
}

.headerCell {
  display: flex;
  flex: 1;
  align-items: center;
  color: var(--main-text-color);
  font-weight: 800;
  background: var(--primary-light-grey);

  justify-content: space-between;
  margin: auto;
  height: 100%;
  padding: 5px;
}

.headerLabel {
  margin: 0 auto;
}

.headerCellSizer {
  border-right: 1px solid var(--primary-white);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.headerCell > label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
}

.cell {
  padding: 5px;
  font-weight: 500;
  border-top: 1px solid var(--primary-light-grey);
  border-bottom: 1px solid var(--primary-light-grey);
  color: var(--main-text-color);
  border-right: 1px solid var(--primary-light-grey);
  display: flex;
}

@keyframes fade-in {
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}
