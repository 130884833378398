.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 3px;
  margin: auto;
}

.active {
  color: var(--primary-dark-green);
}

.inactive {
  color: var(--primary-dark-red);
}
