.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 3px;
  margin: auto;
}

.wrapper:hover {
  border: 1px solid var(--primary-ligth-gray);
}
