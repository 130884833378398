.wrapper {
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07);
  animation-name: fade-in;
  animation-duration: 300ms;
  animation-timing-function: var(--base-timing-fn);
}

.wrapper :global(.ant-card-head-title) {
  color: var(--main-text-color);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fade-in {
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}
