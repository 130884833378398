.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper .icon {
  color: var(--secondary-mid-gray);
}

.wrapper .icon:hover {
  color: var(--primary-gray);
}

.wrapper .icon.selected {
  color: var(--secondary-mid-blue);
}
