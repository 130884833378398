@import './css/variables/colors.css';
@import './css/variables/dimensions.css';
@import './css/variables/animations.css';

* {
  box-sizing: border-box;
  outline: none;
}

html {
  min-height: 100%;
  background: var(--main-app-bgr);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-typography,
h3.ant-typography {
  color: var(--main-text-color);
}
