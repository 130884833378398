:root {
  --primary: #5a35c5;
  --primary-green: #48c961;
  --primary-blue: #276cff;
  --primary-grey: #a7a7a7;
  --primary-dark-grey: #aeaeae;
  --primary-light-grey: #e6e6e6;
  --primary-very-light-grey: #f2f2f2;
  --primary-dark-green: #299e00;
  --primary-dark-red: #c80f0f;
  --primary-white: #fff;

  --secondary-light-grey: #d9d9d9;
  --secondary-mid-blue: #1890ff;
  --secondary-mid-gray: #bfbfbf;

  --main-app-bgr: #efefef;
  --main-text-color: #4e4e4e;

  --charcoal-red: #f57a5c;
  --carrot-orange: #ee9b21;
}
