.wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
}

.select {
  font-weight: 500;
  text-align: center;
  min-width: 15rem;
}

.select :global(.ant-select-selector) {
  border-color: var(--secondary-light-grey) !important;
}

.select:global(.ant-select-focused) :global(.ant-select-selector) {
  box-shadow: none !important;
  outline: none !important;
  border-color: var(--primary) !important;
}

.statusSelect {
  width: 7rem;
}
